@font-face {
  font-family: "Nexa";
  src: url("./assets/fonts/NexaBold.woff2") format("woff2"),
    url("./assets/fonts/NexaBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nexa";
  src: url("./assets/fonts/NexaLight.woff2") format("woff2"),
    url("./assets/fonts/NexaLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

.App {
  scroll-behavior: smooth;
  font-family: "Nexa";
  color: #323c42;
  line-height: 100%;
}

a {
  color: #323c42;
  display: inline-block;
  text-decoration: none !important;
}
a:hover {
  color: #323c42;
}
ul,
ol {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nexa";
  font-weight: bold;
}
.clr_blue {
  color: #009fe9;
}
p {
  line-height: 150%;
  font-size: 18px;
  color: #323c42;
}

input,
textarea,
select {
  outline: none !important;
  height: 50px;
  width: 100%;
  background-color: #f9f9f9;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #eaeaea;
}
textarea {
  height: 120px;
  resize: none;
}
label {
  font-size: 13px;
  font-weight: bold;
}

header {
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #ffffff 0%,
    #fbfbfb 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #ffffff 0%,
    #fbfbfb 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #fbfbfb 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#fbfbfb',GradientType=0 );
  padding-bottom: 90px;
}
header ul li a.active {
  color: #e5007e;
}
header .dropdown-menu li a {
  font-size: 13px;
}
header .dropdown-menu li a:hover {
  color: #000;
}
.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  outline: none;
  width: 60px;
}
.line {
  fill: none;
  stroke: #323c42;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.banner {
  padding: 80px 0px;
  overflow: hidden;
}
.banner_img {
  width: 60%;
  float: right;
}
.banner h1 {
  font-size: 64px;
}
.btn_ {
  font-size: 18px;
  font-weight: bold;
  color: #e5007e;
  position: relative;
  transition: all ease 0.3s;
  padding-right: 50px;
}
.btn_:after,
.product:after {
  content: "";
  display: block;
  border-right: 2px solid #e5007e;
  border-bottom: 2px solid #e5007e;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  transition: all ease 0.3s;
}
.btn_:before,
.product:before {
  width: 34px;
  height: 34px;
  background: rgba(199, 0, 126, 0.1);
  content: "";
  display: block;
  border-radius: 100%;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  transition: all ease 0.3s;
}
.btn_:hover {
  color: #b80065;
}
.btn_:hover:before {
  background-color: #b80065;
  width: 30px;
  height: 30px;
}
.btn_:hover:after {
  border-color: #fff;
  right: 12px;
}
.banner_content {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.stats h2 {
  font-weight: 300;
}
.stats .icon_ {
  text-align: center;
  line-height: 80px;
  background-color: #fdf100;
  width: 80px;
  height: 80px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  margin-right: 20px;
}
.stats strong {
  display: block;
  font-size: 36px;
  line-height: 100%;
}
.stats strong span {
  display: block;
  font-size: 14px;
  line-height: 14px;
}

.btn_fl {
  border: 0px none;
  font-size: 24px;
  background-color: #e5007e;
  display: inline-block;
  padding: 0px 25px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-weight: bold;
  height: 65px;
  line-height: 70px;
  color: #fff;
  transition: all ease 0.3s;
}
.btn_fl:hover {
  color: #fff;
  background-color: #b80065;
}

.btn_fl.blue {
  background: #009fe9;
}
.btn_fl.blue:hover {
  background-color: #0387c4;
}
.btn_fl.wp {
  background: #3fda26;
  padding: 0px;
  width: 65px;
  text-align: center;
}
.btn_fl.wp img {
  width: 34px;
}
.btn_fl.wp:hover {
  background-color: #30b81c;
}

.products {
  padding-top: 100px;
}
.products h3 {
  font-size: 48px;
}
.products h3 span {
  color: #009fe9;
  font-size: 24px;
  margin-left: 20px;
  font-family: "Caveat", cursive;
}

.product {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  transition: all ease 0.3s;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
}
.product strong {
  font-size: 20px;
}
.product:before,
.product:after {
  bottom: 15px;
  right: 20px;
  top: auto;
  transform: translateY(0);
  background-color: rgba(0, 0, 0, 0.1);
}
.product:after {
  transform: rotate(-45deg);
  right: 34px;
  bottom: 27px;
  background: none;
  border-color: #b1b1b1;
}
.product:hover {
  color: #e5007e;
  transform: scale(1.05);
}
.product:hover:before {
  background-color: #e5007e;
}
.product:hover:after {
  border-color: #fff;
}

.about {
  padding: 100px 0px;
  position: relative;
}
.about:before,
.about:after,
footer:after {
  content: "";
  display: block;
  background-color: #009fe9;
  position: absolute;
  top: 0px;
  right: 25%;
  bottom: 0px;
  left: 0px;
  z-index: -1;
}
.about:after {
  top: -100px;
}
.about h4 {
  font-size: 48px;
  color: #fff;
}
.about p {
  color: #fff;
}
p.lg_fnt {
  font-size: 24px;
}
.about .btn_fl {
  background-color: #000;
}
.about .btn_fl:hover {
  background-color: #fff;
  color: #009fe9;
}
.about .btn_ {
  color: #fff;
}
.about .btn_:before {
  background-color: rgba(255, 255, 255, 0.25);
}
.about .btn_:after {
  border-color: #fff;
}
.about .btn_:hover:after {
  border-color: #009fe9;
}
.about .btn_:hover:before {
  background-color: #fff;
}
.about img {
  box-shadow: 30px 34px 80px rgba(0, 0, 0, 0.2);
}

.testimonial {
  padding: 100px 0px;
}
.testimonial p {
  font-size: 36px;
}
.testimonial strong {
  font-size: 24px;
}
.testimonial strong span {
  font-size: 14px;
  color: #e5007e;
  margin-bottom: 10px;
}

.slider {
  width: 70%;
}
.slider .slide {
  padding: 0px 50px 60px;
}
.testimonial .container:before {
  content: "";
  display: block;
  background: url("./assets/img/quote.svg") no-repeat;
  width: 170px;
  height: 114px;
  background-size: cover;
  position: absolute;
  left: 0px;
  top: 0px;
}

.testimonial:before,
.testimonial:after {
  content: "";
  display: block;
  width: 240px;
  height: 245px;
  background: url("./assets/img/printer_1.jpg") no-repeat;
  background-size: cover;
  position: absolute;
  left: 40px;
  bottom: 50px;
}
.testimonial:after {
  background: url("./assets/img/printer_2.png") no-repeat;
  width: 500px;
  height: 320px;
  background-size: cover;
  left: auto;
  right: 40px;
  bottom: -130px;
}

footer {
  padding: 100px 0px 30px;
}
footer:after {
  background-color: #f9f9f9;
  right: 15%;
}
footer h6 {
  color: #979797;
  font-size: 18px;
}
footer span {
  display: block;
  font-size: 14px;
  color: #000;
  font-weight: bold;
  margin-bottom: 10px;
}
.address a {
  font-size: 34px;
  font-weight: bold;
  color: #000;
  line-height: 34px;
}
.address strong a {
  position: relative;
}
.address strong a:before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background: url("./assets/img/arrow.svg") no-repeat;
  position: absolute;
  right: -15px;
  top: -10px;
}

footer ol li a {
  opacity: 0.1;
  transition: all ease 0.3s;
}
footer ol li a:hover {
  opacity: 1;
}
footer p {
  color: #b1b1b1;
  font-size: 12px;
  margin-bottom: 0px;
  font-weight: bold;
}

.banner_ {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 60px;
  z-index: 9;
}
.banner_ h1 {
  font-size: 48px;
  color: #fff;
}
.banner_ span {
  color: #fff;
  font-size: 18px;
}
.subpage_banner .position-relative:after {
  content: "";
  display: block;
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 0px;
  top: 0px;
  border-radius: 16px;
  background: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#00000000',GradientType=1 );
}
.subpage_banner img {
  border-radius: 16px;
}

.about_card {
  background-color: #fdf100;
  padding: 60px 40px 20px;
  border-radius: 16px;
  color: #000;
  position: relative;
}
.about_card h2 {
  font-size: 24px;
}
.about_card img {
  height: 48px;
  margin-bottom: 20px;
  position: absolute;
  right: 30px;
  top: 30px;
}

.contact_page address {
  line-height: 170%;
}
.contact_page address b {
  display: block;
  opacity: 0.5;
}
.contact_page .bg-light span {
  font-weight: bold;
  display: block;
  font-size: 13px;
  color: #323c42;
  margin-bottom: 10px;
  opacity: 0.5;
}
.contact_page .bg-light a {
  font-size: 24px;
  font-weight: bold;
}

.brands {
  overflow: hidden;
  margin: 0px -5px;
}
.brand-badge {
  position: relative;
  float: left;
  margin: 5px;
}
.brand-badge input {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0;
}
.brand-badge label {
  margin: 0px;
  background-color: #eaeaea;
  border-radius: 8px;
  padding: 10px 15px;
  display: inline-block;
}

.brand-badge input:checked ~ label {
  background-color: #009fe9;
  color: #fff;
}

.filter {
  border-radius: 16px;
  box-shadow: -20px 20px 50px rgba(0, 0, 0, 0.1);
}
.filter h4 {
  color: #323c42;
  font-size: 14px;
  text-transform: uppercase;
  opacity: 0.5;
}

.cat_link {
  position: relative;
  padding: 10px 0px 0px;
}
.cat_link label {
  font-size: 18px;
  padding-left: 30px;
}
.cat_link input {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 5;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.cat_link label:after {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  content: "";
  display: block;
  border-radius: 4px;
  position: absolute;
  left: 0px;
  top: 9px;
}
.cat_link label:before {
  display: block;
  content: "";
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='229.153px' height='229.153px' viewBox='0 0 229.153 229.153' style='enable-background:new 0 0 229.153 229.153;' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M92.356,223.549c7.41,7.5,23.914,5.014,25.691-6.779c11.056-73.217,66.378-134.985,108.243-193.189 C237.898,7.452,211.207-7.87,199.75,8.067C161.493,61.249,113.274,117.21,94.41,181.744 c-21.557-22.031-43.201-43.853-67.379-63.212c-15.312-12.265-37.215,9.343-21.738,21.737 C36.794,165.501,64.017,194.924,92.356,223.549z'/%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
  width: 15px;
  height: 15px;
  background-size: contain;
  position: absolute;
  left: 3px;
  top: 6px;
  z-index: 9;
  background-repeat: no-repeat;
  opacity: 0;
  transition: all ease 0.3s;
}

.cat_link input:checked ~ label:after {
  background: #fdf100;
}
.cat_link input:checked ~ label:before {
  opacity: 1;
}

.content_pr h6 {
  font-size: 24px;
}
.content_pr p {
  font-size: 14px;
}

.list {
  display: block;
  margin-top: 20px;
}
.list li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 20px;
  font-size: 18px;
  line-height: 150%;
}
.list li:before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: #009fe9;
  position: absolute;
  left: 0px;
  top: 7px;
}

.product_content p {
  font-size: 24px;
  margin-top: 20px;
}

.filter_btn:before {
  content: "Show Filter";
  display: block;
  font-size: 18px;
}

.pagination {
  justify-content: center;
}
.pagination li {
  width: 35px;
  height: 35px;
  /* border: 1px solid #b80065; */
  background-color: rgba(199, 0, 126, 0.1);
  font-weight: bold;
  color: #b80065;
  border-radius: 50%;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  transition: all ease 0.3s;
}
.pagination li:hover {
  background-color: #b80065;
  color: #fff;
}
/* Shimmer Effect */

.shimmerBG {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1200px 100%;
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}

.media {
  height: 195px;
}
.media_h {
  height: 195px;
  border-radius: 5px;
}

.p-10 {
  padding: 10px;
}

.title-line {
  height: 24px;
  width: 75%;
  margin-bottom: 12px;
  border-radius: 20px;
}

.content-line {
  height: 8px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 8px;
}

.end {
  width: 40%;
}

.m-t-24 {
  margin-top: 24px;
}

/* Shimmer Effect */

.product-img-frame {
  aspect-ratio: 3/2;
}

.object-fit-contain {
  object-fit: contain;
}

.aspect-85-63 {
  aspect-ratio: 85/63;
}

@media (max-width: 1199px) {
  .banner h1 {
    font-size: 48px;
  }
  .btn_fl,
  .contact_page .bg-light a {
    font-size: 18px;
  }
  .product strong {
    font-size: 14px;
  }
  p.lg_fnt {
    font-size: 18px;
  }
  .testimonial p {
    font-size: 30px;
  }
  .address a {
    font-size: 26px;
  }
}

@media (max-width: 991px) {
  .products .product {
    margin-top: 30px;
  }
  .products h3 span {
    display: block;
    margin: 0px !important;
  }
  .banner h1 {
    font-size: 36px;
  }
  .about:before,
  .about:after,
  footer:after {
    right: 0px !important;
  }
  .testimonial .container:before {
    width: 60px;
    height: 40px;
  }
  .slider {
    width: 90%;
  }
  .testimonial:before,
  .testimonial:after {
    width: 120px !important;
    background-size: contain !important;
    height: 120px !important;
  }
  .testimonial:after {
    width: 300px !important;
    height: 200px !important;
  }
  .address a {
    font-size: 22px !important;
  }
  footer span {
    margin: 0px !important;
  }
  .address strong a:before {
    top: 0px;
    width: 10px;
    height: 10px;
    background-size: contain;
    right: -10px;
  }

  .menu.opened {
    position: fixed;
    z-index: 100;
    right: 20px;
    width: 40px;
    top: 20px;
    background: #eaeaea;
    border-radius: 4px;
  }
  .menu_links {
    display: none;
  }
  .menu_links.active {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 99;
    display: block;
  }
  .menu_links.active:after {
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    bottom: 0px;
    right: 0px;
    top: 0px;
    left: 0px;
    position: absolute;
    content: "";
    display: block;
  }
  .menu_links.active .overlay_menu {
    padding-bottom: 76px;
    overflow: hidden;
    overflow-y: scroll;
    background: #fff;
    bottom: 0px;
    right: 0px;
    top: 0px;
    position: absolute;
    width: 260px;
    z-index: 9;
    box-shadow: -10px 0px 100px rgba(0, 0, 0, 0.2);
  }
  .menu_links.active .overlay_menu ul.d-lg-flex {
    margin: 40px 0px 0px !important;
  }
  .menu_links.active .overlay_menu ul li {
    padding: 0px !important;
  }
  .menu_links.active .overlay_menu ul li a {
    font-size: 16px;
    position: relative;
    display: block;
    padding: 20px 30px;
  }
  .menu_links.active .overlay_menu ul li a:before {
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: #e8e8e8;
    background: -moz-linear-gradient(left, #e8e8e8 0%, #ffffff 100%);
    background: -webkit-linear-gradient(left, #e8e8e8 0%, #ffffff 100%);
    background: linear-gradient(to right, #e8e8e8 0%, #ffffff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8e8e8', endColorstr='#ffffff',GradientType=1 );
  }

  .menu_links.active .wp {
    position: fixed;
    bottom: 0px;
    width: 260px;
    font-size: 20px;
    right: 0px;
    background-color: #fff;
    border-top: 1px solid #eaeaea;
    padding: 30px 0px 30px 20px;
  }
  .menu_links.active .wp img {
    display: block;
    position: absolute;
    right: 20px;
    top: 7px;
    width: 24px;
  }

  .menu_links.active .overlay_menu .dropdown-menu {
    padding: 0px !important;
    float: none;
    position: static !important;
    transform: translate(0) !important;
    width: 100% !important;
    box-shadow: none !important;
    border: 0px none !important;
    background-color: #f9f9f9 !important;
    margin: 0px !important;
  }

  .filter {
    display: none;
  }
  .filter.collapse.show {
    display: block;
  }

  .sub-head {
    padding-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .banner h1,
  .stats h2 {
    font-size: 26px;
  }
  .btn_ {
    font-size: 14px;
  }
  .stats strong,
  .testimonial p {
    font-size: 20px;
  }
  .testimonial strong {
    font-size: 16px;
  }
  .slick-slide img {
    width: 60px;
  }
  .testimonial strong span {
    margin: 0px;
  }
  .address a {
    font-size: 16px !important;
  }
  .sub-head {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .menu {
    position: absolute;
    top: -55px;
  }
  .testimonial:before {
    display: none;
  }
  .testimonial:after {
    width: 200px !important;
  }
  .slider {
    width: 100%;
  }
  .testimonial .container:before {
    top: -60px !important;
    left: 64px;
  }
  .testimonial,
  header {
    padding-bottom: 50px;
  }
  .btn_fl {
    font-size: 13px;
    padding: 15px;
    height: auto;
    line-height: 13px;
  }
  .banner h1,
  .stats h2 {
    font-size: 18px;
  }
  .banner_img {
    width: 50%;
  }
  .products {
    padding-top: 50px;
  }
  .products h3 {
    font-size: 24px;
  }
  .products h3 span {
    display: none;
  }
  .about {
    padding: 50px 0px;
  }
  .subpage_banner {
    margin-top: 30px;
  }
  .banner_ h1 {
    font-size: 24px;
  }
  .banner_ span {
    font-size: 14px;
  }

  .image.w-75,
  .image.w-75 img {
    width: 100% !important;
  }
  .content_pr {
    padding: 0px 30px 30px !important;
  }
  .product_card .float-right {
    float: none !important;
  }
}
